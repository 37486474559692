<template>
  <div>
    <headers />
    <div class="mb row wid" style="padding:15px;padding-top: 70px !important;">
      <div class="col-12">
        <div class="page-title-box">
          <h3><div class="lang" v-html="lan.needs"></div></h3> <span class="btn btn-primary waves-effect waves-light btn-back-fixed" style="float:right" @click="saveData()"><div class="lang" v-html="lan.save"></div></span>
        </div>
        <div class="col-11 mb-0 alert alert-primary" v-if="saveAlert === 1">{{$language('ご意見有難う御座います。内容を確認してシステム上に反映します。')}}</div>
        <div class="col-11 mb-0 alert alert-danger" v-if="saveAlert === 9">{{$language('保存に失敗しました')}}</div>
      </div>
    
      <div class="col-12">
         <div class="tab-box" >
            <ul class="tab-head nav nav-tabs">
               <li  class="nav-item" data-id="tc1" @click="tab2('tc1')" v-bind:class="[bcurindex=='tc1' ? 'nav-item active show':'nav-item']">
                <a data-toggle="tab" class="nav-link" aria-expanded="true"><div class="lang" v-html="lan.areas" ></div></a>
              </li> 
            
              <li  class="nav-item" data-id="tc2" @click="tab2('tc2')" v-bind:class="[bcurindex=='tc2' ? 'nav-item active show':'nav-item']">
                <a data-toggle="tab" class="nav-link" aria-expanded="true"><div class="lang" v-html="lan.type" ></div></a>
              </li>  
              <!--<li  class="nav-item" data-id="tc2" @click="tab2('tc3')" v-bind:class="[bcurindex=='tc3' ? 'nav-item active show':'nav-item']">
                <a data-toggle="tab" class="nav-link" aria-expanded="true"><div class="lang" v-html="lan.office_filter" ></div></a>
              </li> -->
              
            </ul>
            
        </div>
        <div class="tab-content" style="width:100%;background:#fff">

          <div class="embed-responsive-16by9 tab-pane show active tc1" v-if="bcurindex=='tc1'">
                  
                 
                  <div class=""><div class="left"><div class="lang" v-html="lan.areas"></div><div class="help">
                    <ctooltip :text="'<i class=\'mdi mdi-help-circle-outline\'></i>'" :align="'right'" :help_text="lan.help_cbreai_setting" />
                    </div>
                  </div>
                    <div class="right">
             
                      <div class="" v-for="(item, index) in menu_data"  :key="index">  
                        <div class="level1"><input type="checkbox" :value="item['StateID']" :checked="StateIDS.includes(item['StateID'].toString())" @click="checked(item['StateID'])"> {{item["StateJa"]}}</div>
                  
                      </div>          
                    </div>
                  </div>
            </div>
        </div>
         <div class="embed-responsive-16by9 tab-pane show active tc2" v-if="bcurindex=='tc2'">   
           <div class=""><div class="left"><div class="lang" v-html="lan.type"></div></div>
                    <div class="right">
          
                      <div class="" v-for="(item, index) in industry_data"  :key="index">  
                        <div class="level1"><input type="checkbox" :value="item['code']" :checked="industry.includes(item['code'])" @click="checked_industry(item['code'],1)"> {{item["name"]}}
                        </div>
                  
                      </div>          
                    </div>
                  </div> 
        </div>
        <div class="embed-responsive-16by9 tab-pane show active tc3" v-if="bcurindex=='tc3'">   

           <div class="" v-for="(item, index) in setting"  :key="index">
            <div class="left">
           
            <div class="lang" v-html="lan[item.key]"></div>
          </div>
              <div class="right">
                <select v-model="setting[index].value" v-if="item.type=='select'">
                  <option v-for="(item2, index2) in item.items.split('@')"  :key="index2" :value="item2.split('=')[0]">{{item2.split("=")[1]}}</option>
                </select>
                <div class="" v-if="item.type=='input'">  
                  <div class="level1"><input type="text" v-model="setting[index].value" ></div>
                
                </div> 
                <div class="" v-if="item.type=='input_more'">  
                  <div class="level1" v-for="(item2, index2) in item.value.split('@')" :key="index2" >
                    <div v-if="item2.split('=')>1" >
                      <input type="hidden" v-model="nsetting[item.key]['type'][index2]" class="input">
                      <input type="text" v-model="nsetting[item.key]['id'][index2]" >
                      <input type="text" v-model="nsetting[item.key]['name'][index2]" >
                    </div>
                    <div v-if="item2.split('=')==1">
                      <input type="text" v-model="nsetting[item.key][index2]" >
                    </div>
                  </div>
                </div>
                <div class="notice">{{item.remark}}</div>    
              </div>
            </div> 
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.help{position: relative;margin-left: 10px;    display: inline-block;}
.help-box{margin-top:10%;width: 60%;}
.popup-modal {
  display: block;
}

.popup-bg {
  background: rgba(0, 0, 0, .25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 8;
}

.popup {
  background: #fff;
  border-radius: .15rem;
  box-shadow: 0 .15rem .5rem rgba(0, 0, 0, .25);
  left: 50% !important;
  max-height: 85vh;
  max-width: 90%;
  position: fixed;
  top: 2.5rem !important;
  transform: translateX(-50%) !important;
  z-index: 16;
}

.popup iframe {
  width: 100%;
}

.fade-leave-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}
.tagbox{
    display: flex;
    align-items: center;
    justify-content: center;
  }
.closes{position: absolute;
cursor: pointer;
        right: 16px;
    top: 4px;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 20px;
    line-height: 26px;
    padding: 2px 9px;
    border-radius: 15px;}
.wid{width: 80%;margin-left: auto;margin-right: auto;}
.left{width:15%;float: left;margin-right: 1%;}
.right{width:84%;float: left;}
.level1{border-radius: 5px;
    padding: 5px;
    width: 30%;
    margin: 10px 1%;
    float: left;
    border: 1px solid #ccc;}
.level2{padding-left: 20px;}
.level3{padding-left: 40px;}
.line{height: 50px;line-height: 50px;border-bottom: 1px solid #ccc;    margin-bottom: 20px;}
.notice{width: 100%;font-size: 14px;color: #666; clear: both;}
</style>
<script>
import axios from 'axios';
import { inject } from 'vue';
import Headers from '@/components/Headers.vue'
import Ctooltip from '@/components/Ctooltip';

export default {
  name: 'custom_needs',
  components: {
    Headers,Ctooltip
  },
  data() {
    return {
      menu_data:[],
      cur_lan:'jp',
      section:'offices',
      lan:inject('global').language_data,lan_help:inject('global').language_help_data,
      saveAlert:0,
      industry:[],
      bcurindex:'tc1',
      menu:[],
      areas:[],
      StateIDS:[],
      isPopup:false,
      setting:[],
      nsetting:[],
    }
  },
   mounted() {
     //let language_data=JSON.parse(localStorage.getItem('language_data'));
    //this.lan=language_data
    this.getMenu();
    this.getIndustry();
    this.getMysetting();
  },
  methods:{
    tab2(id){
      this.bcurindex=id;
    },
    getMysetting(){
      axios.get('/v1/getCbreaiSetting',{params:{email:localStorage.getItem('email'),id_token:localStorage.getItem('id_token')} })
      .then(response => {
        
          if(response.data.status==1){
            this.industry=response.data.data.industry!=undefined && response.data.data.industry!=null ? response.data.data.industry.split(','):[];
            this.setting=response.data.setting;
            for(var d in this.setting){
              if(this.setting[d].type=='input_more'){
                var nv=this.setting[d].value.split("@");
                console.log(nv)
                if(this.setting[d].value.indexOf("=")>0){
                  let s=0;
                  let ndata={"type":[],"id":[],"name":[]};
                  for(var nn in nv){
                    var ddd=nv[nn].split("=");
                    ndata['type'][s]=ddd[0];
                    ndata['id'][s]=ddd[1];
                    ndata['name'][s]=ddd[2];
                  }
                  this.nsetting[this.setting[d].key]=ndata;
                }else{
                  this.nsetting[this.setting[d].key]=nv;
                }
                
              }
            }
            console.log(this.nsetting);
            this.StateIDS=response.data.data.StateID!=undefined && response.data.data.StateID!=null ? response.data.data.StateID.split(','):[];
            //console.log(this.StateIDS)
            //console.log(this.setting)
          }
          
      })
      .catch(error => (
          console.log(error)
        )
      );
      
    },
    getIndustry(){
      axios.get('/v1/getIndustry',{params:{id_token:localStorage.getItem('id_token')} })
      .then(response => {
          if(response.data.status==1){
            this.industry_data=response.data.data;
          }
          
      })
      .catch(error => (
          console.log(error)
        )
      );
      
    },
     
    checked_industry(item){
      console.log(item)
      var aa=this.industry.indexOf(item);
      if(aa>-1){
        this.industry.splice(aa,1);
      }else{
        this.industry.push(item)
      }
    },
    checked(item){
      console.log(item)
      var aa=this.StateIDS.indexOf(item);
      if(aa>-1){
        this.StateIDS.splice(aa,1);

      }else{
        this.StateIDS.push(item)
      }
    },
    saveData(){
axios.get('/v1/saveCbreaiSetting',{params:{email:localStorage.getItem('email'),StateID:this.StateIDS.join(','),industry:this.industry.join(','), id_token:localStorage.getItem('id_token')} })
      .then(response => {
          if(response.data.status==1){
            this.saveAlert=1;
          }else{
             this.saveAlert=9;
          }
         
      })
      .catch(error => (
          console.log(error)
        )
      );
    },
    getMenu(){
        var upd=localStorage.getItem('lan_updated_at_'+this.cur_lan);
         this.menu=JSON.parse(localStorage.getItem('menu_data_'+this.cur_lan));
     axios.get('https://api.cbreai.com/api/v1/research/getLanguageMenuAI',{params:{updated_at:upd==null ?'2021-01-01':upd,language:this.cur_lan, id_token:localStorage.getItem('id_token')} })
      .then(response => {
          this.menu_data=response.data.area_data;
         
      })
      .catch(error => (
          console.log(error)
        )
      );
    },
    
  }
}
</script>